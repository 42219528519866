<template>
  <SideBar @toggle="$emit('toggle')" class="is-large" ref="sideBar">
    <DoodleGeneralForm
      title="Sign up to JDoodle"
      in-progress-message="JDoodle is trying to sign you up, Please wait..."
      success-message="Successfully Updated."
      action-text="Sign up"
      :form="doodleForm"
      :inProgress="doodleForm.inProgress"
      :completed="doodleForm.completed"
      :errorMessageText="doodleForm.errorMessage"
      @action="register"
      @close="$refs.sideBar.emitToggle()"
    >
      <p class="recommended">We recommended using</p>
      <div class="google_or_mic">
        <div class="google" @click="googleSignIn">
          <img src="../assets/images/header/google.svg" />
          <p>Sign in with Google</p>
        </div>
        <div class="mic" @click="microsoftSignIn">
          <img src="../assets/images/header/microsoft.svg" />
          <p>Sign in with Microsoft</p>
        </div>
      </div>

      <div class="or">
        <span></span>
        <p>or</p>
        <span></span>
      </div>

      <div class="level is-mobile register">
        <div class="level-item has-text-black new-to-message   ">
          <span>Already have an account? </span>
          &nbsp;&nbsp;
          <a
            @click="$emit('changeComponent', 'Login')"
            class="is-link is-underlined is-info"
          >
            <strong>Sign In</strong>
          </a>
        </div>
      </div>
      <DoodleInput
        field-name="username"
        label-text="Email ID"
        type="text"
        leftIcon="envelope"
        v-model="doodleForm.username"
        rule="required|email"
        place-holder="joe@example.com"
      />
      <DoodleInput
        field-name="displayName"
        label-text="Display Name"
        type="text"
        left-icon="address-card"
        rule="required"
        v-model="doodleForm.displayName"
        place-holder="New Display Name"
      />
      <DoodleInput
        field-name="newPassword"
        label-text="New Password"
        type="password"
        left-icon="lock"
        rule="required|min:5|max:50"
        v-model="doodleForm.newPassword"
        place-holder=""
        reff="newPassword"
      />
      <DoodleInput
        field-name="retypeNewPassword"
        label-text="Retype New Password"
        type="password"
        left-icon="lock"
        :rule="{ required: true, confirmed: doodleForm.newPassword }"
        v-model="doodleForm.retypeNewPassword"
        place-holder=""
      />
      <div id="gRecaptcha"></div>
      <div class="has-text-centered terms">
        By registering, you agree to the
        <a href="/terms" target="_blank">Terms of Service</a>
      </div>
      <template v-slot:successMessage>
        <div class="has-text-centered">
          <div class="register-thanks">
            Thanks for registering!!!
          </div>

          <div class="register-followup">
            We have sent you an email for confirmation. <br />
            <span class="register-followup-em"
              >Before login, Please check your email</span
            >
            to complete the registration!
          </div>
        </div>
      </template>
    </DoodleGeneralForm>
  </SideBar>
</template>

<script>
import formMixin from '../assets/javascript/form-mixin'
import DoodleInput from './Utils/DoodleInput'
import DoodleGeneralForm from './Utils/DoodleGeneralForm'
import SideBar from './Utils/SideBar'
import oauthMixin from '../assets/javascript/oauth-mixin'

export default {
  name: 'register',
  mixins: [formMixin, oauthMixin],
  inject: ['$validator'],
  components: { SideBar, DoodleGeneralForm, DoodleInput },
  data: function () {
    return {
      doodleForm: {
        username: null,
        displayName: null,
        newPassword: null,
        retypeNewPassword: null,
        recaptcha: null
      }
    }
  },
  mounted () {
    if (this.$store.state.isUserLoggedIn === true) {
      this.$router.push('/')
    }

    let render = count => {
      if (count > 50) {
        return
      }

      if (window.grecaptcha) {
        window.grecaptcha.render('gRecaptcha', {
          sitekey: '6LfrpXEUAAAAAPBKa1tVgQ5ynOq-Gt_hqHpx2awO',
          callback: this.setCaptcha
        })
      } else {
        this.$_.delay(render, 500, count + 1)
      }
    }

    this.$_.delay(render, 10, 1)
  },
  methods: {
    setCaptcha (gc) {
      this.doodleForm.recaptcha = gc
    },
    register () {
      this.doodleForm.errorMessage = ''
      if (!this.doodleForm.recaptcha) {
        this.doodleForm.errorMessage = 'Please click on "I\'m not a robot"'
        return
      }
      this.executeAPI({
        url: '/api/register/register',
        data: {
          username: this.doodleForm.username,
          displayName: this.doodleForm.displayName,
          newPassword: this.doodleForm.newPassword,
          retypePassword: this.doodleForm.retypeNewPassword,
          recaptcha: this.doodleForm.recaptcha
        },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GLOBAL,
        jdaEvent: 'register',
        jdaLabel: ''
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../node_modules/bulma-divider/dist/css/bulma-divider.min.css";
@import "../assets/style/oauth";

.new-to-message {
  margin-right: 1em;
}

.is-divider {
  margin: 0;
}
.register {
  position: relative;
  div {
    position: absolute;
    right: 0px;
    bottom: -40px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    color: #000000;
  }
}
.google_or_mic {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    padding: 15px 20px;
    align-items: center;
    border: 1px solid #30475e;
    border-radius: 5px;
    width: 48%;
    cursor: pointer;
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      margin-left: 10px;
    }
  }
}
.recommended {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin-top: 12px;
}
.or {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 36px;
  span {
    width: 100%;
    border: 1px solid #ebebeb;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
}
.register-thanks {
  font-weight: bold;
  color: green;
  margin-bottom: 15px;
}

.register-followup {
  color: chocolate;
  line-height: 2em;
  font-style: italic;
  font-family: serif;
  font-size: 16px;
}

.register-followup-em {
  text-decoration: underline;
  font-weight: bold;
}

.privacy-respect {
  font-size: 0.75em;
  margin-top: -10px;
  margin-bottom: 10px;
}

.terms {
  font-size: 0.9em;
  margin-top: 10px;
}

@media only screen and (max-width: 550px) {
  .google_or_mic {
    flex-direction: column;
    align-items: center;
    gap: 19px;
    div {
      width: 200px;
      justify-content: center;
    }
  }
  .recommended {
    margin-top: 24px;
    text-align: center;
  }
}
</style>
